<template>
	<div>
		<pui-datatable
			v-if="masterDetail"
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
		<div v-else>
			<v-row class="px-4">
				<v-col cols="12" md="3">
					<pui-select
						:id="`resourcetype-${modelName}`"
						:attach="`resourcetype-${modelName}`"
						v-model="data"
						:label="$t('form.resourcerequest.resourcetype')"
						required
						toplabel
						clearable
						modelName="resourcetype"
						:itemsToSelect="resourceItemsToSelect"
						:modelFormMapping="{ resourcetypeid: 'resourcetype' }"
						itemValue="resourcetypeid"
						itemText="name"
						:order="{ name: 'asc' }"
						reactive
					></pui-select>
				</v-col>
				<v-col>
					<v-row v-if="data.resourcetype != null">
						<v-col class="max-width-3">
							<pui-select
								:id="`portid-${modelName}`"
								:attach="`portid-${modelName}`"
								v-model="data"
								:label="$t('grid.resources.portid')"
								toplabel
								clearable
								modelName="port"
								:itemsToSelect="[{ id: data.portid }]"
								:modelFormMapping="{ id: 'portid' }"
								itemValue="id"
								itemText="portname"
								reactive
							></pui-select>
						</v-col>
						<grid-export-btn :modelName="modelName" :masterModelName="modelName" :headers="headers" :items="tableData"></grid-export-btn>

						<v-col class="max-width-1">
							<v-menu :close-on-content-click="false" bottom offset-y>
								<template v-slot:activator="{ on, attrs }">
									<v-btn class="ma-2 mt-5" :color="data.filter.date.active ? 'primary' : ''" v-bind="attrs" v-on="on" icon
										><v-icon>fa fa-filter</v-icon></v-btn
									>
								</template>
								<v-card class="pa-3">
									<v-row>
										<v-col class="max-width-4">
											<v-row>
												<v-col xs12>
													<label>{{ $t('grid.resources.column') }}</label>
												</v-col>
											</v-row>
											<v-select
												v-model="data.filter.date.column"
												solo
												flat
												clearable
												item-text="text"
												item-value="value"
												:items="dateFilterColumns"
											></v-select>
										</v-col>
										<v-col class="max-width-4">
											<pui-date-field
												:id="`startdate-${modelName}`"
												v-model="data.filter.date.startdate"
												:label="$t('grid.resources.since')"
												toplabel
											></pui-date-field>
										</v-col>
										<v-col class="max-width-4">
											<pui-date-field
												:id="`enddate-${modelName}`"
												v-model="data.filter.date.enddate"
												:label="$t('grid.resources.to')"
												toplabel
											></pui-date-field>
										</v-col>
									</v-row>
									<v-btn color="primary" @click="data.filter.date.active = !data.filter.date.active" style="color: white">
										{{ !data.filter.date.active ? $t('grid.resources.activate') : $t('grid.resources.disable') }}
									</v-btn>
								</v-card>
							</v-menu>
						</v-col>
						<v-col class="max-width-6">
							<v-text-field
								v-model="data.filter.search"
								class="mt-5"
								prepend-inner-icon="fa fa-search"
								placeholder="Buscar"
								hide-details
								single-line
								solo
								outlined
								flat
							></v-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<div v-resize="onResize" class="px-4">
				<v-data-table
					:headers="!isEmpty(data.resourcetype) ? headers : []"
					:items="!isEmpty(data.resourcetype) ? tableData : []"
					class="operations-dt-pui9-styles"
					:options.sync="options"
					:server-items-length="tableDataCount"
					:loading="loading"
					fixed-header
					:height="tableHeight"
					@dblclick:row="goToForm"
				></v-data-table>
			</div>
		</div>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import { disable } from 'ol/rotationconstraint';

export default {
	name: 'resourcesgrid',
	components: {},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'resources',
			modelColumnDefs: {},
			data: {
				resourcetype: null,
				portid: null,
				filter: {
					search: null,
					date: {
						active: false,
						column: null,
						startdate: null,
						enddate: null
					}
				}
			},
			tableData: [],
			activityHeaders: [],
			modelConfiguration: null,
			options: {},
			loading: false,
			tableHeight: 0,
			userPortIds: [],
			resourceFilter: {}
		};
	},
	computed: {
		resourceItemsToSelect() {
			return [{ resourcetypeid: this.data.resourcetype }];
		},
		headers() {
			return [...this.masterProviderHeaders(), ...this.activityHeaders];
		},
		dateFilterColumns() {
			return this.headers?.filter((column) => column.columntype == 'datetime');
		},
		tableDataCount() {
			return this.tableData.length;
		},
		fixedResourceFilter() {
			if (this.data.resourcetype != null) {
				this.resourceFilter = {
					groupOp: 'and',
					groups: [],
					rules:
						this.data.portid != null
							? [
									{ field: 'resourcetypeid', op: 'eq', data: this.data.resourcetype },
									{ field: 'portid', op: 'in', data: this.data.portid },
									{ field: 'portauthorityid', op: 'eq', data: window.localStorage.getItem('workingPortAuthorityId') }
							  ]
							: [
									{ field: 'resourcetypeid', op: 'eq', data: this.data.resourcetype },
									{ field: 'portid', op: 'in', data: this.userPortIds },
									{ field: 'portauthorityid', op: 'eq', data: window.localStorage.getItem('workingPortAuthorityId') }
							  ]
				};
			} else {
				this.resourceFilter = {
					groupOp: 'and',
					groups: [],
					rules: []
				};
			}

			return this.resourceFilter;
		},
		dateFilterJsonParams() {
			const isJsonDate = this.activityHeaders.some((header) => header.value == this.data.filter.date.column);
			const params = {};
			if (this.data.filter.date.active && isJsonDate) {
				params.column = this.data.filter.date.column;
				params.startdate = this.data.filter.date.startdate;
				params.enddate = this.data.filter.date.enddate;
			}
			return params;
		},
		dateFilter() {
			const isJsonDate = this.activityHeaders.some((header) => header.value == this.data.filter.date.column);

			const filter = {
				groupOp: 'and',
				groups: [],
				rules: []
			};
			if (this.data.filter.date.active && !isJsonDate) {
				if (!this.isEmpty(this.data.filter.date.startdate))
					filter.rules.push({ field: this.data.filter.date.column, op: 'ge', data: this.data.filter.date.startdate });
				if (!this.isEmpty(this.data.filter.date.enddate))
					filter.rules.push({ field: this.data.filter.date.column, op: 'le', data: this.data.filter.date.enddate });
			}
			return filter;
		},
		deepWatchDateFilter() {
			return Object.assign({}, this.data.filter.date);
		}
	},

	methods: {
		getTableData() {
			this.loading = true;
			const { page, itemsPerPage } = this.options;
			const bodyRequest = {
				model: this.modelName,
				queryFields: this.getQueryFields(),
				queryText: this.getQueryText(),
				order: this.getOrder(),
				queryLang: this.$store.getters.getRequestLang || this.$store.getters.getUserLanguage,
				page: page || 1,
				rows: itemsPerPage || 10,
				filter: this.getFilter()
			};
			this.$puiRequests.postRequest(
				this.modelConfiguration.url.listWithAttributes,
				bodyRequest,
				(response) => {
					const gridData = response.data.data;
					const rowsWithActivities = gridData.filter((row) => row.resourcesjson != null);
					const mapActivityHeaders = (activity, index) => {
						//console.log(activity);
						return {
							text: activity.title,
							value: `col_activity_${index}`,
							columntype: activity.type_attribute_id == 4 ? 'datetime' : 'text',
							disabled: activity.disabled
						};
					};
					if (this.isEmpty(this.activityHeaders)) {
						this.activityHeaders =
							rowsWithActivities.length != 0
								? JSON.parse(rowsWithActivities[0].resourcesjson)
										.map(mapActivityHeaders)
										.filter((row) => row.disabled != 1)
								: [];
					}
					this.tableData = gridData.map(this.mapActivitiesToRow);
					this.loading = false;
				},
				(e) => {
					this.loading = false;
					this.$puiNotify.error(e.response.data, this.$puiI18n.t('menu.resources'), 2000);
				},
				null,
				this.dateFilterJsonParams
			);
		},
		mapActivitiesToRow(row) {
			const dateColumns = this.modelConfiguration?.columns.filter((column) => column.type == 'datetime' && column.visibility == 'visible');

			dateColumns.forEach((dateColumn) => {
				row[dateColumn.name] = this.formatDate(row[dateColumn.name]);
			});
			if (row.resourcesjson != null) {
				const activities = JSON.parse(row.resourcesjson);
				activities.forEach((activity, index) => {
					row[`col_activity_${index}`] =
						activity.value && activity.type_attribute_id == 4 ? this.formatDate(activity.value) : activity.value;
				});
			}
			return row;
		},
		formatDate(stringDate) {
			return !this.isEmpty(stringDate)
				? this.$moment(stringDate).format(`${this.$store.getters.dateFormat} ${this.$store.getters.timeFormat}`)
				: null;
		},
		goToForm(MouseEvent, { item }) {
			this.goRouter('resources', { resourceid: item.resourceid });
		},
		onResize() {
			this.tableHeight = window.innerHeight - 310;
		},
		isEmpty(...values) {
			return values.some(
				(value) =>
					value === undefined ||
					value === null ||
					(typeof value === 'string' && value.trim() === '') ||
					(Array.isArray(value) && value.length === 0)
			);
		},
		goRouter(modelName, objectPk, method, replace = false, extraParameters = []) {
			if (!modelName) return;
			const arrayParametros = extraParameters.join('/');
			let url;
			if (!objectPk || method === 'create') {
				url = `${modelName}/create/e30=/${arrayParametros}`;
			} else {
				const pk = window.btoa(JSON.stringify(objectPk));
				url = `${modelName}/${method || 'update'}/${pk}/${arrayParametros}`;
			}
			const params = {
				router: this.$router,
				url: url,
				params: null,
				query: {},
				replace: replace
			};
			this.$store.dispatch('puiRouterGoToUrl', params);
		},
		getOrder() {
			const { sortBy, sortDesc } = this.options;
			return sortBy && sortBy.length !== 0 ? [{ column: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc' }] : null;
		},
		getQueryFields() {
			return [...this.masterProviderHeaders().map((header) => header.value)];
		},
		getQueryText() {
			return this.isEmpty(this.data.filter.search) ? null : this.data.filter.search;
		},
		getOrder() {
			const { sortBy, sortDesc } = this.options;
			return sortBy && sortBy.length !== 0 ? [{ column: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc' }] : null;
		},
		getFilter() {
			if (this.dateFilter.rules.length > 0) {
				return { groupOp: 'and', groups: [this.fixedResourceFilter, this.dateFilter], rules: [] };
			}
			return { groupOp: 'and', groups: [this.fixedResourceFilter], rules: [] };
		},
		masterProviderHeaders() {
			console.log(this.modelConfiguration);
			return this.modelConfiguration
				? this.modelConfiguration?.columns
						?.filter((column) => column.visibility == 'visible' || column.name == 'community')
						.map((visibleColumn) => {
							return { text: this.$t(visibleColumn.title), value: visibleColumn.name, columntype: visibleColumn.type };
						})
				: [];
		}
	},
	watch: {
		'$store.state.modelsLoadedAndSaved'() {
			this.modelConfiguration = this.$store.getters.getModelByName(this.modelName);
			console.log(this.modelConfiguration);
		},
		'data.resourcetype'(newValue, oldValue) {
			if (newValue) {
				this.getTableData();
			}
			if (newValue !== oldValue) {
				this.activityHeaders = [];
				this.options.sortBy = [];
				this.options.sortDesc = [];
				this.options.page = 1;
			}
		},
		'data.portid'(newValue, oldValue) {
			if (newValue) {
				this.getTableData();
			}
			if (newValue !== oldValue) {
				this.activityHeaders = [];
				this.options.sortBy = [];
				this.options.sortDesc = [];
				this.options.page = 1;
			}
		},
		options: {
			handler() {
				if (this.data.resourcetype) this.getTableData();
			},
			deep: true
		},
		'data.filter.search'() {
			this.getTableData();
		},
		deepWatchDateFilter: {
			deep: true,
			handler(newValue, oldValue) {
				if (newValue.active || newValue.active != oldValue.active) this.getTableData();
			}
		}
	},
	mounted() {
		this.userPortIds = this.$store.getters.getPortsIds;
		this.modelConfiguration = this.$store.getters.getModelByName(this.modelName);
		if (this.modelConfiguration) {
			this.data.resourcetype = this.modelConfiguration[`${this.modelName}_resourcetype`];
		}
	},
	beforeDestroy() {
		if (this.modelConfiguration) this.modelConfiguration[`${this.modelName}_resourcetype`] = this.data.resourcetype;
	}
};
</script>
